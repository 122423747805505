
import VimeoPlayer from './_vimeoPlayer.webpack.js';

export default class {
  constructor() {
    if($(".vmPlayer").length){
      this.init();
    }
  }

  init(){
    this.players=[];
    this.modalVideoPlayer=null;

    // play-inview があれば画面内のみ再生
    $(".vmPlayer").each((i,el)=>{
      var $el=$(el);
      // IDがなければ追加
      if(!el.id)el.id = "vmPlayer__"+i;

      // vimeoのID追加
      var videoID = $el.attr("data-videoID-sp")&&ROOT.isSp ? $el.attr("data-videoID-sp"):$el.attr("data-videoID");

      // オプションの有無
      var option = $el.attr("data-option");
      if (option) {
        option = (new Function("return " + option))();
      }

      // プレーヤー作成
      this.players[i] = new VimeoPlayer(el.id,videoID,option);
      $el.data("vmPlayer",this.players[i]);


      // ビデオが画面内に入ったら再生・出たらポーズ
      if($el.hasClass("play-inview")){
        UIkit.scrollspy(el,{repeat:true});
        $(el).on("inview",()=>{
          this.players[i].play();
        }).on("outview",()=>{
          this.players[i].pause();
        })
      }

      // モーダルの指定
      var $modal=$el.parents(".modal")
      if($modal.length){
        this.modalVideoPlayer=this.players[i];
        $modal.on("MODAL-OPEN",()=>{
          videoID = $el.attr("data-videoID-sp") && ROOT.isSp ? $el.attr("data-videoID-sp") : $el.attr("data-videoID");
          if(this.modalVideoPlayer.videoID!=videoID){
            this.modalVideoPlayer.loadVideo(videoID);
          }else{
            this.modalVideoPlayer.play();
          }

          // var $videoSizeholder = $modal.find(".setVideoSize");
          // if ($videoSizeholder){
          //   Promise.all([this.modalVideoPlayer.player.getVideoWidth(), this.modalVideoPlayer.player.getVideoHeight()]).then(function (dimensions) {
          //     var width = dimensions[0];
          //     var height = dimensions[1];
          //     $videoSizeholder.css({ "padding-top": height / width * $videoSizeholder.width() })
          //   });
          // }
        })

        $modal.on("MODAL-CLOSE",(obj)=>{
          $el.attr({
            // "data-videoID":"",
            // "data-videoID-sp":""
          });
          this.modalVideoPlayer.pause();
        })

      }

      // 動画 リサイズ による 動画指定
      if(!$modal.length)ROOT.ev.on("ON_CHANGE_TARGET",()=>{
        videoID = $el.attr("data-videoID-sp")&&ROOT.isSp ? $el.attr("data-videoID-sp"):$el.attr("data-videoID");
        if(this.players[i].videoID!=videoID){
          this.players[i].loadVideo(videoID);
        }
      });

    })

  }
}
