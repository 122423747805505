export default class {
  constructor(){
    var self=this;
    $(document).ready(()=>{
      self.init()
    })
  }
  init(){
    var self=this;


    /*
    * もっと見る
    * .btn-more-item
    * data-item-holder
    * data-target-itemClass
    * data-target-nextPageClass
    */
    $(".btn-more-item").each(function(){
      var $this = $(this);
      var $itemHolder = $($this.attr("data-item-holder"));
      var targetItemClass = $this.attr("data-target-itemClass");
      var targetNextPageClass = $this.attr("data-target-nextPageClass");
      if($(targetNextPageClass).length==0){
        $this.hide();
      }else{


        //カテゴリーの or 検索用
        var nextUrl=$(targetNextPageClass).attr("href");
        if(nextUrl){
          var matchArr=nextUrl.match(/\/category\/(.*)\//);
          if(matchArr&&matchArr[1]&&matchArr[1].indexOf(",")!==-1){
            $(targetNextPageClass).attr("href",nextUrl.replace(matchArr[0],"/item_list.html").replace("?","?category_cd="+matchArr[1]+"&"))
          }
        }



        $itemHolder.infiniteScroll({
          path: targetNextPageClass,
          append: targetItemClass,
          button: ".btn-more-item",
          // history: false,
          scrollThreshold: false,
          status: '.page-load-status',
        }).on('request.infiniteScroll', function( event, path ) {
          $this.addClass("hide");
        }).on('load.infiniteScroll',(event, response, path)=>{
          // console.log(response)
        }).on('append.infiniteScroll',(event, response, path,items)=>{

          $(items).find(".img-holder img").each(function(){
            $(this).addClass("img-lazy-mode lazyload");
            var src=this.src;
            $(this).removeAttr("src");
            $(this).attr("data-src",src);
            ROOT.ev.emit("LOAD_MORE_APPENDED");
          });

          //カテゴリーの or 検索用
          $(items).each((i,el)=>{
            var itemUrl=$(".img-holder a",el).attr("href");
            if(itemUrl){
              var matchArr=itemUrl.match(/\/category\/(.*)\//);
              if(matchArr&&matchArr[1]&&matchArr[1].indexOf(",")!==-1){
                $(".img-holder a",el).attr("href",itemUrl.replace(matchArr[0],"/ITEM/"));
              }
            }
          });

          if(ROOT.pageID=="item_list$FEATURE"||ROOT.pageID=="item_list$detail"){
            ROOT.html.setItemListStylesFeature();
          }

          self.wishCheck()

          $this.removeClass("hide");
        })
      }

    })
  }
  wishCheck(){
    $('#itemListDetail .wishlistSubmitButton').each(function(){
      var $self = $(this);
      if($self.find(".wishStatus").hasClass("wished")) {
        $self.find(".wishlistinserttag").hide();
        $self.find(".wishlistdeletetag").show();
      } else {
        $self.find(".wishlistinserttag").show();
        $self.find(".wishlistdeletetag").hide();
      }
    });
  }
}
