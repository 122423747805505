export default class {
  constructor(){
    document.addEventListener("DOMContentLoaded", (e)=> {
      if(ROOT.pageID=="item_detail" && location.hash!="#debug")this.init();
    })
  }
  init(){

    // レコメンド スタイル
    $(".section-recommend-style .list-item").each((i,el)=>{
      var itemCode = $(".item-code",el).text();
      if(itemCode){
        var item = _.find(ROOT.JSON_DATA.items,{ITEM_CD:itemCode});
        if(item){
          var cateArr=[];_.each(_.range(30),(n,i)=>{ if(item['CATEGORY_CD'+i]) cateArr.push(item['CATEGORY_CD'+i]); });
          var isStyles = cateArr.some(str => str.indexOf("STYLES")!==-1);
          if(!isStyles)$(el).remove();
        }
      }
    })
    if($(".section-recommend-style .list-item").length==0){ $(".section-recommend-style").remove(); }

    // レコメンド 特集
    $(".section-recommend-feature .list-item").each((i,el)=>{
      var itemCode = $(".item-code",el).text();
      if(itemCode){
        var item = _.find(ROOT.JSON_DATA.items,{ITEM_CD:itemCode});
        if(item){
          var cateArr=[];_.each(_.range(30),(n,i)=>{ if(item['CATEGORY_CD'+i]) cateArr.push(item['CATEGORY_CD'+i]); });
          var isFeatures = cateArr.some(str => str.indexOf("FEATURE")!==-1);
          if(!isFeatures)$(el).remove();
        }
      }
    })
    if($(".section-recommend-feature .list-item").length==0){ $(".section-recommend-feature").remove(); }

    // レコメンド アイテム
    $(".section-recommend-item .list-item").each((i,el)=>{
      var itemCode = $(".item-code",el).text();
      if(itemCode){
        var item = _.find(ROOT.JSON_DATA.items,{ITEM_CD:itemCode});
        if(item){
          var cateArr=[];_.each(_.range(30),(n,i)=>{ if(item['CATEGORY_CD'+i]) cateArr.push(item['CATEGORY_CD'+i]); });
          var isStyles = cateArr.some(str => str.indexOf("STYLES")!==-1);
          var isFeatures = cateArr.some(str => str.indexOf("FEATURE")!==-1);
          if(isStyles||isFeatures)$(el).remove();
        }
      }
    })
    if($(".section-recommend-item .list-item").length==0){ $(".section-recommend-item").remove(); }


    // MJSコード

    /* シングル バリデーション debug
    *****************************
    $(".item-shopping-btn-group .valiation-buttons").remove()
    $(".valiationGroup").remove();
    $(".item-color-list-holder").remove();
    */



    var self = this;
    var data={
      /*
      valiants:[],
      valiation:{},
      valiationSelects:[]
      */
      tagCategorys:{
        functions:[],
        tabs:[]
      },
      viewmode:0,
      swipers:[],
      itemImages:[],
      selectedValiation:{},
      imgValiatinIndex:null

    }
    var computed={
    }
    var watch={
    }

    // アイテム情報の登録
    $(`[data-item]`).each(function(){
      data[$(this).attr("data-item")]=$(this).text().trim();
    })

    var teikaPrice = data["price_zeikomi"];
    if(teikaPrice){
      teikaPrice = ~~teikaPrice.replace(/¥|￥|,/g,"");
    }

    data.itemName=$(".item-shopping-title-holder .item-name").text().trim();
    var contactHref=$(".btn-contact").attr("href");
    $(".btn-contact").attr("href",contactHref+data.itemName);


    // シェアボタン
    $(".default-buttons .btn-share").attr("onClick",`window.execCopy('${location.href}',this)`);

    // バリエーション商品情報の登録
    // valiants
    var valiants=data.valiants=[],key,value;
    $(".item-shopping-btn-group .valiation-buttons").each((n,el)=>{
      valiants[n] = {
        button:$(el).find(".valiation-button").html(),
        funcButton:$(el).find(".valiation-function-button"),
      };
      $(el).find("[data-valiat]").each(function(){
        key = $(this).attr('data-valiat');
        value = $(this).text().trim();
        if(key=="label"){
          value = value.split(",");
          value = value.map(v => v.trim());
        } else if (value==""){
          value = false;
        }
        // console.log(n,key,value);
        valiants[n][key] = value;
      });


      // 一部会員限定セール
      if(valiants[n].price){
        var valiationPrice = ~~valiants[n].price.replace(/¥|￥|,/g,"");
        if(teikaPrice != valiationPrice){
        console.log(teikaPrice,valiationPrice)
          if(data["limited_sale"] == "一部会員限定セール"){
            if(!data["is_login"]){
              $(el).find(".valiation-button").html($('.btn-limited-member').html())
              valiants[n].button = $('.btn-limited-member').html();
            }else{
              $(el).find(".valiation-button .btn-text").append('<span class="txt-henpin">（返品不可）</span>')
              valiants[n].button=valiants[n].button.replace(/カートに入れる/g,'カートに入れる<span class="txt-henpin">（返品不可）</span>');
            }
            valiants[n].priceSale = valiants[n].price;
            valiants[n].price = data["price_zeikomi"];

          }else if(data["limited_sale"] == "会員限定セール"){

            var priceSale = valiants[n].priceSale.replace(/¥|￥|,/g,"");
            var diffPrice = teikaPrice - priceSale;
            if(diffPrice){
              data.diffPrice=true;
              valiants[n].priceSale = Math.round(valiationPrice-diffPrice);
              valiants[n].priceSale = "￥"+valiants[n].priceSale.toLocaleString();
            }else{
              // valiants[n].priceSale=null;
            }
          }
        }
      }else{
        valiants[n].price = data["price_zeikomi"];
      }


      // シェアボタン
      $(el).find(".btn-share").attr("onClick",`window.execCopy('${location.href.replace(".html","-"+valiants[n].val+".html")}')`)
      $(el).attr("v-if",`selectedVals=='${valiants[n].val}'`);
    });

    // valiation の定義
    var valiation=data.valiation={},index=0;
    _.each(valiants,(item,i)=>{
      var val = item.val.split("-");
      if(val.length==1){
        valiation[val]=Object.assign({index:i},item);
        valiation[val].label=item.label[0];
      }else{
        if(!valiation[val[0]]){
          valiation[val[0]]={
            index:index,
            label:item.label[0],
          };
          index++;
        }
        valiation[val[0]][val[1]]=Object.assign({},item);
        valiation[val[0]][val[1]].label=item.label[1];
      }
    });





    // 初期イメージ登録
    $(".default-images .image-item img").each(function(){
      data.itemImages.push({label:"", src:this.src});
    });


    // バリエーション画像の登録
    $(".item-color-list-holder .list-item").each(function(n,el){
      var label = $(el).find(".item-title").text().trim();
      if(label)var valTarget = _.findWhere(valiation,{label:label});
      //items=[];
      if(valTarget)
      $(el).find(".img-holder").each(function(i){
        var src=$(this).find("img").attr("src")||$(this).find("img").attr("data-src")
        if(i==0){
          valTarget.thumb = src;
          valTarget.pics = [];
          return true;
        }
        valTarget.pics.push(src);
        //items.push(src);
        $(this).remove();
        data.itemImages.push({label:label,src:src});
      });
    });


    if(data.itemImages.length==1){
      $('.item-shopping-holder').addClass("single")
    }

    var colorOrder = $(".colorOrder").text().trim();
    if(colorOrder)colorOrder=colorOrder.split(",");

    // バリエーションの登録（出力されるバリエーションのセレクタの解析）
    var valiationSelects=data.valiationSelects=[];
    var defaultSize=null,colorSelects;
    $(`.valiationGroup`).each(function(n){
      var $self=$(this);
      var obj={}
      valiationSelects[n]=obj;
      var $select=$self.find("select");
      var title=$self.find(".title").text();
      obj.title = title;
      obj.items = [];
      obj.model = $select.val()
      obj.$select=$select;

      var labelArr=[];
      $select.attr("v-model",`valiationSelects[${n}].modal`);
      $select.find("option").each(function(i){
        var label = $(this).text().trim();
        var val = $(this).val();
        if(val == "")label = "";
        obj.items.push({index:i,label:label,val:val});
        labelArr.push(label);
      });

      // 画像の配列からラベルの配列のみを取得「かぶり」をなくす
      var arr=_.uniq(_.pluck(data.itemImages,"label"));
      if(arr[0]=="")arr.shift();

      // 画像のと同じ並びのバリエーショングループを取得
      labelArr.shift();
      //console.log("画像のと同じ並びのバリエーショングループを取得",arr,labelArr)
      if(_.isEqual(arr,labelArr)){
        data.imgValiatinIndex = n;

        // カラバリソート（自由項目７）にサムネ画像を並び替える
        obj.items=_.sortBy(obj.items,(item)=>{
          item.sort=_.indexOf(colorOrder,item.label);
          if(item.sort==-1 && item.label)item.sort=100;
          return item.sort;
        });
      }

      /* デフォルトの選択を
      *******************************/
      if(obj.title=="カラー"){
        var targetInfo;
        colorSelects=obj
        _.each(obj.items,(item)=>{
          if(item.label==""){return;}
          targetInfo = _.filter(valiants,(o)=>{ return o.label[0] == item.label; });
          var isAllOut=_.every(targetInfo, function(o) {return o.isNyuka!=false || o.isSoldout!=false;});
          item.isAllOut=isAllOut;
          if(isAllOut)item.sort=1000;
        });

        // ソート
        obj.items = _.sortBy(obj.items,"sort");

        // サイズ取得
        targetInfo = _.filter(valiants,(o)=>{ return o.label[0] == obj.items[1].label; });


        // Mの在庫取得
        _.each(targetInfo,(item,i)=>{
          if(item.label[1] == "M" && !item.isNyuka && !item.isSoldout){
            defaultSize=item.val.split("-")[1];
          }
        })

        // Mが在庫切れなら他のもの
        if(!defaultSize){
          _.some(targetInfo,(item,i)=>{
            if(!item.isNyuka && !item.isSoldout){
              defaultSize=item.val.split("-")[1];
            }
            return defaultSize;
          })
        }
        // カラーの場合は画像ありとする
        // data.imgValiatinIndex = n;
        if(obj.model==""){
          obj.model=obj.items[1].val;
        }

      } else if(obj.title=="サイズ"){

        if(obj.model==""){
          if(defaultSize){ obj.model=defaultSize; }
          else{
            var $sizeM = obj.$select.find("option[value=M]");
            if($sizeM.length){
              obj.model = obj.$select.find("option[value=M]").attr("selected","selected").val();
            }else{
              obj.model = obj.$select.find("option:eq(1)").attr("selected","selected").val();
            }
          }
        }
      }
    });


    // カラバリソート（在庫有無）に LARGE 画像を並び替える
    data.itemImages=_.sortBy(data.itemImages,(item)=>{
      if(colorSelects)item.sort=_.findIndex(colorSelects.items,{label:item.label});
      else item.sort=_.indexOf(colorOrder,item.label);
      if(item.sort==-1&&item.label)item.sort=1000;
      return item.sort;
    });


    $(`.self-categorys .cate`).each((i,el)=>{
      var cateArr = [];
      $(el).find(`a`).each((i,el)=>{
        var label = $(el).text().trim();
        var href = $(el).attr("href");
        if(label=="アイテム"){return true;}
        cateArr.push({label:label,href:href});
        //console.log(label)
      });
      if(_.some(cateArr,{label:"機能"})){
        data.tagCategorys.functions.push(_.last(cateArr));
      }else if(_.some(cateArr,{label:"レーベル"})){
        data.tagCategorys.tabs.push(_.last(cateArr));
      }else if(_.some(cateArr,{label:"アクティビティ"})){
        data.tagCategorys.tabs.push(_.last(cateArr));
      }else if(_.some(cateArr,{label:"タイプ"})){
        data.tagCategorys.tabs.push(_.last(cateArr));
      }
    });

    data.tagCategorys.tabs = _.uniq(data.tagCategorys.tabs,"label");

    // console.log(data.tagCategorys.functions[0][1]);

    this.vue = new Vue({
      delimiters: ['[[',']]'],
      el: ".vueApp",
      data: Object.assign(data,{
        modal:null,
        imageViewer:null,
        preImageGroup:null,
        itemInfomation:null
      }),
      created(){
        if(this.valiants.length){
          $(".default-buttons .valiation-button").hide()
        }

      },
      mounted(){

        //this.openPhotoSwipe();
        this.swipers = ROOT.slider.setItemDetailSwiper();
        this.debounce=_.debounce((n)=>{
          this.swipers[0].slideTo(n);
        }, 100);

      },
      computed:Object.assign(computed, {
        selectedLabels:function(){
          var labels=[]
          for(var n in this.valiationSelects){
            var obj = this.valiationSelects[n];
            var targetObj=_.findWhere(obj.items,{val:obj.model});
            labels.push(targetObj.label);
          }
          return labels.join(" ").trim()
        },
        selectedVals:function() {
          var vals=[];
          for(var n in this.valiationSelects){
            var obj = this.valiationSelects[n];
            vals.push(obj.model);
          }

          // 入荷待ちと売り切れ表示
          for(var n in this.valiationSelects){
            var obj = this.valiationSelects[n];
            _.each(obj.items,(item,i)=>{
              var _arr=vals.concat();
              _arr[n]=item.val;
              var targetInfo = _.findWhere(this.valiants,{val:_arr.join("-")});
              if(targetInfo){
                item.isSoldout=targetInfo.isSoldout?1:0;
                item.isNyuka=targetInfo.isNyuka?1:0;
              }
            })
          }

          this.selectedValiation = _.findWhere(this.valiants,{val:vals.join("-")});


          if(this.diffPrice && this.selectedValiation){

            if(this.selectedValiation.priceSale){
              var priceSale=this.selectedValiation.priceSale.replace(/￥|\¥|\,|（税込）/g,"");
            }
            if(originalPrice=this.selectedValiation.price){
              var originalPrice=this.selectedValiation.price.replace(/￥|\¥|\,|（税込）/g,"");
            }
            if(priceSale&&originalPrice){
              var offPrice=Math.round((originalPrice-priceSale)/originalPrice*100);
            }

            $(".item-shopping-holder .price-off").text(`[ ${offPrice}% OFF ]`)

          } else {
            // this.selectedValiation={}
          }


          return vals.join("-");
        },
        // ROOT.vueItemDetail.vue.selectedValiation

        currentImageGroup:function() {
          if(this.imgValiatinIndex!=null){
            var val=this.valiationSelects[this.imgValiatinIndex].model;
            var index=_.findIndex(this.valiationSelects[this.imgValiatinIndex].items, {val:val});
            var label=this.valiation[val].label;
            var itemIndex=_.findIndex(this.itemImages, {label:label});
            if(ROOT.isSp){
              if(this.swipers[0]){
                this.debounce(itemIndex)
              }
            }else{
              if(this.preImageGroup!=null){
                ROOT.scrollCommon.gotoScroll($(".item-image-holder .image-item").eq(itemIndex)[0]);
                ROOT.ev.emit("ON_CHANGE_ITEM_IMAGE")
              }
            }
            this.preImageGroup=index;
            return index;
          }else{
            this.preImageGroup=0;
            return 0;
          }
        }
      }),
      watch:Object.assign(watch,{
        modal:function(){
          if(this.modal=="imageViewer"){
            setTimeout(()=>{
              this.openPhotoSwipe()
            },1)
          }else if(this.modal==null){
            if(this.pswp){
              this.pswp.close();
            }
          }
        },
        viewmode:function(){
          ROOT.scrollCommon.gotoScroll($(".item-image-holder .image-item").eq(0)[0]);
          if(ROOT.isSp){
            setTimeout(function(){
              ROOT.ev.emit("ON_CHANGE_MODE",1);
            },10)
          }
        },
        itemInfomation:function(str){
          if(str=='detail'){
            UIkit.tab('.detail-tab-group').show(0);
          }
          if (str=='size') {
            UIkit.tab('.detail-tab-group').show(1);
          }
        }
      }),
      methods:{
        openPhotoSwipe: function(){
          this.pswpElement = document.querySelectorAll('.pswp')[0];
          var items=[]

          _.each(this.itemImages,(item,i)=>{
            items.push({
              src:item.src,
              w:1680,
              h:2240
            })
          });

          var options = {
            index:this.imageViewer,
            history: false,
            focus: false,
            spacing:0,
            maxSpreadZoom:2,
            pinchToClose:false,
            closeOnScroll:false,
            closeOnVerticalDrag:false,
            mouseUsed:true,
            preload:[1,1],
            showAnimationDuration: 0,
            hideAnimationDuration: 0,


            barsSize:{top:0, bottom:60},
            //arrowEl:false,
            preloaderEl:false,
            closeEl:false,
            captionEl: false,
            fullscreenEl: false,
            zoomEl: false,
            shareEl: false,
          };
          this.pswp = new PhotoSwipe( this.pswpElement, PhotoSwipeUI_Default, items, options);
          this.pswp.listen('close',()=>{

            this.modal=null;
          });
          this.pswp.init();
          this.pswp.zoomTo(Math.max(ROOT.s.W/1680,1), {x:ROOT.s.W*0.5,y:0});
        }
      }
    })
  }
}
