<template>
  <!-- .search-inner が PC と SP で別れている -->

  <div class="search-container">
    <h2 class="hide">こだわり検索 (<span v-html="totalItem"></span>)</h2>
    <div class="search-inner pcOnly">
      <div class="search-head">
        <div class="sp-flex uk-flex-between">
          <a class="btn btn-item-top" @click="close()">
            <i uk-icon="chevron-left"></i>
            アイテムトップ
          </a>
        </div>
        <div class="uk-width-auto uk-flex uk-flex-middle">
          <div class="item-search-form">
            <input class="uk-input pc-text-size-14 sp-text-size-32" v-model="keyword" placeholder="アイテム検索" type="text" >
            <div class="item-search-submit">
              <i class="text-color-gray icon icon-search" uk-icon="ka-search"></i>
            </div>
          </div>
          <button class="uk-button uk-width-1-3 reset" @click="reset()">条件をクリア</button>
          <button class="uk-button uk-width-2-3 submit" @click="submit()">この条件で検索</button>
          <button class="uk-button close" type="button" @click="close()">
            <i class="icon" uk-icon="icon:ka-close; ratio:2"></i>
          </button>
        </div>
      </div>

      <div class="list-holder">
        <!-- 第0 -->
        <div class="ITEM_003-head">
          <ul class="uk-list-divider uk-width-full margin0">
            <li>
              <a class="item" :class="{'active':ITEM_003==''}" @click="ITEM_003=''">
                <h3 class="item-title">すべて</h3>
                <i class="icon-absolute-right" uk-icon="chevron-right"></i>
              </a>
            </li>
            <li>
              <a class="item" :class="{'active':ITEM_003=='ITEM_003_001'}" @click="ITEM_003='ITEM_003_001'">
                <h3 class="item-title">リュック・バッグ</h3>
                <i class="icon-absolute-right" uk-icon="chevron-right"></i>
              </a>
            </li>
            <li>
              <a class="item" :class="{'active':ITEM_003=='ITEM_003_002'}" @click="ITEM_003='ITEM_003_002'">
                <h3 class="item-title">ウェア</h3>
                <i class="icon-absolute-right" uk-icon="chevron-right"></i>
              </a>
            </li>
            <li>
              <a class="item" :class="{'active':ITEM_003=='ITEM_003_003'}" @click="ITEM_003='ITEM_003_003'">
                <h3 class="item-title">アクセサリー</h3>
                <i class="icon-absolute-right" uk-icon="chevron-right"></i>
              </a>
            </li>
          </ul>
        </div>
        <!-- 第1 -->
        <div :class="{fadeIn:isChangeITEM_003}" data-hierarchy="1">
          <ul class="uk-list-divider uk-width-full margin0">
            <!-- 性別 -->
            <li>
              <div class="item ITEM_004">
                <h3 class="item-title">性別</h3>
                <div class="item-body">
                  <ul class="uk-child-width-1-4 uk-grid-collapse" uk-grid>
                    <li>
                      <label :class="{'active':ITEM_004==''}" @click="ITEM_004=''"
                      class="uk-button uk-button-default">指定なし</label>
                    </li>
                    <li>
                      <label :class="{'active':ITEM_004=='ITEM_004_001'}" @click="ITEM_004='ITEM_004_001'"
                      class="uk-button uk-button-default">
                      男性</label>
                    </li>
                    <li>
                      <label :class="{'active':ITEM_004=='ITEM_004_002'}" @click="ITEM_004='ITEM_004_002'"
                      class="uk-button uk-button-default">
                      女性</label>
                    </li>
                    <li>
                      <label :class="{'active':ITEM_004=='ITEM_004_004'}" @click="ITEM_004='ITEM_004_004'"
                      class="uk-button uk-button-default">
                      キッズ</label>
                    </li>
                  </ul>
                  <transition name="fadeIn">
                    <label v-if="ITEM_004=='ITEM_004_002'" class="unisex">
                      <input type="checkbox" class="uk-checkbox uk-background-default" v-model="ITEM_004_003" value="1">
                      <span class="uk-text-small">ユニセックス商品を含む</span>
                    </label>
                  </transition>
                </div>
              </div>
            </li>
            <!-- タイプ -->
            <li v-if="ITEM_003!=''">
              <a v-if="ITEM_003==''" :class="{'active':selectedCategory1==categorys['ITEM_003']}" class="item" @click="itemClick(1,categorys['ITEM_003'])">
                <h3 class="item-title">タイプ</h3>
                <div class="item-body" v-if="sCategory['ITEM_003']">
                  <div class="sCategory" v-html="sCategory['ITEM_003']"></div>
                </div>
                <i class="icon-absolute-right" uk-icon="chevron-right"></i>
              </a>
              <a v-if="ITEM_003!=''" :class="{'active':selectedCategory1==categorys[ITEM_003]}" class="item" @click="itemClick(1,categorys[ITEM_003])">
                <h3 class="item-title">タイプ</h3>
                <div class="item-body uk-width-expand" v-if="sCategory[ITEM_003]">
                  <div class="sCategory" v-html="sCategory[ITEM_003]"></div>
                </div>
                <i class="icon-absolute-right" uk-icon="chevron-right"></i>
              </a>
            </li>
            <!-- アクティビティ -->
            <li>
              <a class="item" :class="{'active':selectedCategory1==categorys['ITEM_002']}" @click="itemClick(1,categorys['ITEM_002'])">
                <h3 class="item-title">アクティビティ</h3>
                <div class="item-body uk-width-expand" v-if="sCategory['ITEM_002']">
                  <div class="sCategory" v-html="sCategory['ITEM_002']"></div>
                </div>
                <i class="icon-absolute-right" uk-icon="chevron-right"></i>
              </a>
            </li>
            <!-- サイズ -->
            <li v-if="ITEM_003=='ITEM_003_001'">
              <div class="item ITEM_008">
                <h3 class="item-title">サイズ(身長)</h3>
                <div class="item-body">
                  <ul class="uk-child-width-1-4 uk-grid-collapse" uk-grid>
                    <li>
                      <label :class="{'active':ITEM_008==''}" @click="ITEM_008=''"
                      class="uk-button uk-button-default">
                      指定なし</label>
                    </li>
                    <li>
                      <label :class="{'active':ITEM_008=='ITEM_008_001'}" @click="ITEM_008='ITEM_008_001'"
                      class="uk-button uk-button-default">
                      S (~160cm)</label>
                    </li>
                    <li>
                      <label :class="{'active':ITEM_008=='ITEM_008_002'}" @click="ITEM_008='ITEM_008_002'"
                      class="uk-button uk-button-default">
                      M (~175cm)</label>
                    </li>
                    <li>
                      <label :class="{'active':ITEM_008=='ITEM_008_003'}" @click="ITEM_008='ITEM_008_003'"
                      class="uk-button uk-button-default">
                      L (175cm~)</label>
                    </li>
                  </ul>
                </div>
              </div>
            </li>
            <!-- 容量 -->
            <li v-if="ITEM_003=='ITEM_003_001'">
              <div class="item ITEM_007">
                <h3 class="item-title">容量</h3>
                <div class="item-body">
                  <ul class="uk-child-width-1-4 uk-grid-collapse" uk-grid>
                    <li>
                      <label :class="{'active':ITEM_007==''}" @click="ITEM_007=''"
                      class="uk-button uk-button-default">
                      指定なし</label>
                    </li>
                    <li>
                      <label :class="{'active':ITEM_007=='ITEM_007_001'}" @click="ITEM_007='ITEM_007_001'"
                      class="uk-button uk-button-default">
                      小型 (~25ℓ)</label>
                    </li>
                    <li>
                      <label :class="{'active':ITEM_007=='ITEM_007_002'}" @click="ITEM_007='ITEM_007_002'"
                      class="uk-button uk-button-default">
                      中型 (~40ℓ)</label>
                    </li>
                    <li>
                      <label :class="{'active':ITEM_007=='ITEM_007_003'}" @click="ITEM_007='ITEM_007_003'"
                      class="uk-button uk-button-default">
                      大型 (41ℓ~)</label>
                    </li>
                  </ul>
                </div>
              </div>
            </li>
            <!-- 機能 -->
            <li v-if="ITEM_003!=''">
              <a class="item" :class="{'active':selectedCategory1==categorys['ITEM_006']}" @click="itemClick(1,categorys['ITEM_006'])">
                <h3 class="item-title">機能</h3>
                <div class="item-body uk-width-expand" v-if="sCategory['ITEM_006']">
                  <div class="sCategory" v-html="sCategory['ITEM_006']"></div>
                </div>
                <i class="icon-absolute-right" uk-icon="chevron-right"></i>
              </a>
            </li>
            <!-- 価格帯 -->
            <li>
              <div class="item">
                <h3 class="item-title">価格帯</h3>
                <div class="item-body uk-flex uk-flex-middle">
                  <select class="uk-select uk-width-small" v-model="minPrice">
                    <option value="">指定なし</option>
                    <option value="1000"> ¥1,000</option>
                    <option value="2000"> ¥2,000</option>
                    <option value="3000"> ¥3,000</option>
                    <option value="5000"> ¥5,000</option>
                    <option value="10000">¥10,000</option>
                    <option value="20000">¥20,000</option>
                    <option value="30000">¥30,000</option>
                    <option value="40000">¥40,000</option>
                    <option value="50000">¥50,000</option>
                    <option value="60000">¥60,000</option>
                    <option value="70000">¥70,000</option>
                    <option value="80000">¥80,000</option>
                    <option value="90000">¥90,000</option>
                    <option value="100000">¥100,000</option>
                  </select>　〜　<select class="uk-select uk-width-small" v-model="maxPrice">
                    <option value="">指定なし</option>
                    <option value="1000"> ¥1,000</option>
                    <option value="2000"> ¥2,000</option>
                    <option value="3000"> ¥3,000</option>
                    <option value="5000"> ¥5,000</option>
                    <option value="10000">¥10,000</option>
                    <option value="20000">¥20,000</option>
                    <option value="30000">¥30,000</option>
                    <option value="40000">¥40,000</option>
                    <option value="50000">¥50,000</option>
                    <option value="60000">¥60,000</option>
                    <option value="70000">¥70,000</option>
                    <option value="80000">¥80,000</option>
                    <option value="90000">¥90,000</option>
                    <option value="100000">¥100,000</option>
                  </select>
                </div>
              </div>
            </li>
            <!-- カラー -->
            <li>
              <a class="item" :class="{'active':selectedCategory1==categorys['ITEM_005']}" @click="itemClick(1,categorys['ITEM_005'])">
                <h3 class="item-title">カラー</h3>
                <div v-if="sCategory['ITEM_005']" class="item-body uk-width-expand">
                  <div class="sCategory" v-html="sCategory['ITEM_005']"></div>
                </div>
                <i class="icon-absolute-right" uk-icon="chevron-right"></i>
              </a>
            </li>
          </ul>
        </div>

        <!-- 第2　カラー -->
        <div v-if="categorys && selectedCategory1==categorys['ITEM_005']" class="uk-width-full" data-hierarchy="2" :key="categorys['ITEM_005']">
          <div class="head" @click="selectedCategory1=null">
            <div class="item uk-flex-center">
              <i class="icon-absolute-left" uk-icon="chevron-left"></i>
              <h2 class="uk-margin-remove">カラー</h2>
            </div>
          </div>
          <div class="uk-flex uk-child-width-1-3 uk-grid-small color-panel-holder" uk-grid>
            <div v-for="child in categorys['ITEM_005'].CHILDREN" :class="{other:!categorys[child].CATEGORY_IMAGE}">
              <label class="uk-display-panel color-panel">
                <img v-if="categorys[child].CATEGORY_IMAGE" class="img-lazy-mode lazyload uk-cover" :data-src="productImagePath+categorys[child].CATEGORY_IMAGE" alt="">
                <input class="hide" type="checkbox" v-model="checkedCategory" :value="categorys[child].CATEGORY_CD">
                <span class="uk-icon-holder"><i class="uk-position-center" uk-icon="icon: check"></i></span>
                <h6 class="uk-margin-remove hide" v-html="categorys[child].CATEGORY_NAME"></h6>
              </label>
            </div>
          </div>
        </div>
        <!-- 第2　カラー以外 -->
        <div :class="'checklist-'+firstKey" v-if="categorys && selectedCategory1==item && selectedCategory1!=categorys['ITEM_005']" v-for="(item,firstKey) in categorys" class="uk-width-full" data-hierarchy="2" :key="item.CATEGORY_CD">
          <div class="head" @click="selectedCategory1=null">
            <div class="item uk-flex-center">
              <i class="icon-absolute-left" uk-icon="chevron-left"></i>
              <h2 class="uk-margin-remove" v-html="item.CATEGORY_NAME"></h2>
            </div>
          </div>
          <label class="item item-child">
            <input class="uk-radio uk-radio-inverse icon-absolute-right" type="checkbox" @change="clear()" v-model="clearCategory" :value="item.CATEGORY_CD">
            <span class="label">指定なし</span>
          </label>
          <ul class="uk-margin-remove">
            <li v-if="categorys[child] && isITEM_006Mach(child)" v-for="child in item.CHILDREN">
              <div v-if="categorys[child].CHILDREN">
                <div class="child">
                  <label class="item item-child">
                    <input class="uk-radio uk-radio-inverse icon-absolute-right" type="checkbox" v-model="checkedCategory" :value="categorys[child].CATEGORY_CD">
                    <span class="label" v-html="categorys[child].CATEGORY_NAME"></span>
                  </label>
                  <div class="kids">
                    <label class="item item-kids" v-if="categorys[baby]" v-for="baby in categorys[child].CHILDREN">
                      <input class="uk-radio uk-radio-inverse icon-absolute-right" type="checkbox" v-model="checkedCategory" :value="categorys[baby].CATEGORY_CD">
                      <span class="label" v-html="categorys[baby].CATEGORY_NAME"></span>
                    </label>
                  </div>
                </div>
              </div>
              <div v-else class="child">
                <label class="item item-child">
                  <input class="uk-radio uk-radio-inverse icon-absolute-right" type="checkbox" v-model="checkedCategory" :value="categorys[child].CATEGORY_CD">
                  <span class="label" v-html="categorys[child].CATEGORY_NAME"></span>
                </label>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div class="search-inner spOnly" :class="{reverse:!selectedCategory1}">
      <div class="list-holder">
        <transition name="slide-fade" mode="out-in">
          <!-- 第0, 第1 -->
          <div v-if="selectedCategory1==null">
            <div class="search-head">
              <div class="sp-flex uk-flex-between">
                <a class="btn btn-item-top" href="ext/itemlist.html">
                  <i uk-icon="chevron-left"></i>
                  アイテムトップ
                </a>
                <button class="uk-button close" type="button" @click="close()">
                  <i class="icon" uk-icon="icon:ka-close; ratio:2"></i>
                </button>
              </div>
              <div class="uk-width-auto uk-flex uk-flex-middle">
                <div class="item-search-form">
                  <input class="uk-input pc-text-size-14 sp-text-size-32" v-model="keyword" placeholder="アイテム検索" type="text" >
                  <div class="item-search-submit">
                    <i class="text-color-gray icon icon-search" uk-icon="ka-search"></i>
                  </div>
                </div>
              </div>
            </div>

            <div class="ITEM_003-head">
              <ul class="sp-flex margin0">
                <li><a :class="{'active':ITEM_003==''}" @click="ITEM_003=''">すべて</a></li>
                <li><a :class="{'active':ITEM_003=='ITEM_003_001'}" @click="ITEM_003='ITEM_003_001'">リュック・バッグ</a></li>
                <li><a :class="{'active':ITEM_003=='ITEM_003_002'}" @click="ITEM_003='ITEM_003_002'">ウェア</a></li>
                <li><a :class="{'active':ITEM_003=='ITEM_003_003'}" @click="ITEM_003='ITEM_003_003'">アクセサリー</a></li>
              </ul>
            </div>
            <ul class="uk-list-divider uk-width-full margin0" :class="{fadeIn:isChangeITEM_003}" data-hierarchy="1">
              <!-- 性別 -->
              <li>
                <div class="item ITEM_004">
                  <h3 class="item-title">性別</h3>
                  <div class="item-body">
                    <ul class="uk-child-width-1-4 uk-grid-collapse" uk-grid>
                      <li>
                        <label :class="{'active':ITEM_004==''}" @click="ITEM_004=''"
                        class="uk-button uk-button-default">指定なし</label>
                      </li>
                      <li>
                        <label :class="{'active':ITEM_004=='ITEM_004_001'}" @click="ITEM_004='ITEM_004_001'"
                        class="uk-button uk-button-default">
                        男性</label>
                      </li>
                      <li>
                        <label :class="{'active':ITEM_004=='ITEM_004_002'}" @click="ITEM_004='ITEM_004_002'"
                        class="uk-button uk-button-default">
                        女性</label>
                      </li>
                      <li>
                        <label :class="{'active':ITEM_004=='ITEM_004_003'}" @click="ITEM_004='ITEM_004_003'"
                        class="uk-button uk-button-default">
                        キッズ</label>
                      </li>
                    </ul>
                    <transition name="fadeIn">
                      <label v-if="ITEM_004=='ITEM_004_002'" class="unisex">
                        <input type="checkbox" class="uk-checkbox uk-background-default" v-model="ITEM_004_004" value="1">
                        <span class="uk-text-small">ユニセックス商品を含む</span>
                      </label>
                    </transition>
                  </div>
                </div>
              </li>
              <!-- タイプ -->
              <li v-if="ITEM_003!=''">
                <a v-if="ITEM_003==''" class="item" @click="itemClick(1,categorys['ITEM_003'])">
                  <h3 class="item-title">タイプ</h3>
                  <div class="item-body" v-if="sCategory['ITEM_003']">
                    <div class="sCategory" v-html="sCategory['ITEM_003']"></div>
                  </div>
                  <i class="icon-absolute-right" uk-icon="chevron-right"></i>
                </a>
                <a v-if="ITEM_003!=''" class="item" @click="itemClick(1,categorys[ITEM_003])">
                  <h3 class="item-title">タイプ</h3>
                  <div class="item-body uk-width-expand" v-if="sCategory[ITEM_003]">
                    <div class="sCategory" v-html="sCategory[ITEM_003]"></div>
                  </div>
                  <i class="icon-absolute-right" uk-icon="chevron-right"></i>
                </a>
              </li>
              <!-- アクティビティ -->
              <li>
                <a class="item" @click="itemClick(1,categorys['ITEM_002'])">
                  <h3 class="item-title">アクティビティ</h3>
                  <div class="item-body uk-width-expand" v-if="sCategory['ITEM_002']">
                    <div class="sCategory" v-html="sCategory['ITEM_002']"></div>
                  </div>
                  <i class="icon-absolute-right" uk-icon="chevron-right"></i>
                </a>
              </li>
              <!-- サイズ -->
              <li v-if="ITEM_003=='ITEM_003_001'">
                <div class="item ITEM_008">
                  <h3 class="item-title">サイズ(身長)</h3>
                  <div class="item-body">
                    <ul class="uk-child-width-1-4 uk-grid-collapse" uk-grid>
                      <li>
                        <label :class="{'active':ITEM_008==''}" @click="ITEM_008=''"
                        class="uk-button uk-button-default">
                        指定なし</label>
                      </li>
                      <li>
                        <label :class="{'active':ITEM_008=='ITEM_008_001'}" @click="ITEM_008='ITEM_008_001'"
                        class="uk-button uk-button-default">
                        S (~160cm)</label>
                      </li>
                      <li>
                        <label :class="{'active':ITEM_008=='ITEM_008_002'}" @click="ITEM_008='ITEM_008_002'"
                        class="uk-button uk-button-default">
                        M (~175cm)</label>
                      </li>
                      <li>
                        <label :class="{'active':ITEM_008=='ITEM_008_003'}" @click="ITEM_008='ITEM_008_003'"
                        class="uk-button uk-button-default">
                        L (175cm~)</label>
                      </li>
                    </ul>
                  </div>
                </div>
              </li>
              <!-- 容量 -->
              <li v-if="ITEM_003=='ITEM_003_001'">
                <div class="item ITEM_007">
                  <h3 class="item-title">容量</h3>
                  <div class="item-body">
                    <ul class="uk-child-width-1-4 uk-grid-collapse" uk-grid>
                      <li>
                        <label :class="{'active':ITEM_007==''}" @click="ITEM_007=''"
                        class="uk-button uk-button-default">
                        指定なし</label>
                      </li>
                      <li>
                        <label :class="{'active':ITEM_007=='ITEM_007_001'}" @click="ITEM_007='ITEM_007_001'"
                        class="uk-button uk-button-default">
                        小型 (~25ℓ)</label>
                      </li>
                      <li>
                        <label :class="{'active':ITEM_007=='ITEM_007_002'}" @click="ITEM_007='ITEM_007_002'"
                        class="uk-button uk-button-default">
                        中型 (~40ℓ)</label>
                      </li>
                      <li>
                        <label :class="{'active':ITEM_007=='ITEM_007_003'}" @click="ITEM_007='ITEM_007_003'"
                        class="uk-button uk-button-default">
                        大型 (41ℓ~)</label>
                      </li>
                    </ul>
                  </div>
                </div>
              </li>
              <!-- 機能 -->
              <li v-if="ITEM_003!=''">
                <a class="item" @click="itemClick(1,categorys['ITEM_006'])">
                  <h3 class="item-title">機能</h3>
                  <div class="item-body uk-width-expand" v-if="sCategory['ITEM_006']">
                    <div class="sCategory" v-html="sCategory['ITEM_006']"></div>
                  </div>
                  <i class="icon-absolute-right" uk-icon="chevron-right"></i>
                </a>
              </li>
              <!-- 価格帯 -->
              <li>
                <div class="item">
                  <h3 class="item-title">価格帯</h3>
                  <div class="item-body uk-flex uk-flex-middle">
                    <select class="uk-select uk-width-small" v-model="minPrice">
                      <option value="">指定なし</option>
                      <option value="1000"> ¥1,000</option>
                      <option value="2000"> ¥2,000</option>
                      <option value="3000"> ¥3,000</option>
                      <option value="10000">¥10,000</option>
                      <option value="50000">¥50,000</option>
                    </select>　〜　<select class="uk-select uk-width-small" v-model="maxPrice">
                      <option value="">指定なし</option>
                      <option value="1000"> ¥1,000</option>
                      <option value="2000"> ¥2,000</option>
                      <option value="3000"> ¥3,000</option>
                      <option value="10000">¥10,000</option>
                      <option value="50000">¥50,000</option>
                    </select>
                  </div>
                </div>
              </li>
              <!-- カラー -->
              <li>
                <a class="item" @click="itemClick(1,categorys['ITEM_005'])">
                  <h3 class="item-title">カラー</h3>
                  <div v-if="sCategory['ITEM_005']" class="item-body uk-width-expand">
                    <div class="sCategory" v-html="sCategory['ITEM_005']"></div>
                  </div>
                  <i class="icon-absolute-right" uk-icon="chevron-right"></i>
                </a>
              </li>
            </ul>
          </div>
          <!-- 第2　カラー -->
          <div v-if="categorys && selectedCategory1==categorys['ITEM_005']" class="uk-width-full" data-hierarchy="2" :key="categorys['ITEM_005']">
            <div class="head" @click="selectedCategory1=null">
              <div class="item uk-flex-center">
                <i class="icon-absolute-left" uk-icon="chevron-left"></i>
                <h2 class="uk-margin-remove">カラー</h2>
              </div>
            </div>
            <div class="uk-flex uk-child-width-1-3 uk-grid-small color-panel-holder" uk-grid>
              <li v-for="child in categorys['ITEM_005'].CHILDREN" :class="{other:!categorys[child].CATEGORY_IMAGE}">
                <label class="uk-display-panel color-panel">
                  <img v-if="categorys[child].CATEGORY_IMAGE" class="img-lazy-mode lazyload uk-cover" :data-src="productImagePath+categorys[child].CATEGORY_IMAGE" alt="">
                  <input class="hide" type="checkbox" v-model="checkedCategory" :value="categorys[child].CATEGORY_CD">
                  <span class="uk-icon-holder"><i class="uk-position-center" uk-icon="icon: check"></i></span>
                  <h6 class="uk-margin-remove" v-html="categorys[child].CATEGORY_NAME"></h6>
                </label>
              </li>
            </div>
          </div>
          <!-- 第2　カラー以外 -->
          <div :class="'checklist-'+firstKey" v-if="categorys && selectedCategory1==item && selectedCategory1!=categorys['ITEM_005']" v-for="(item,firstKey) in categorys" class="uk-width-full" data-hierarchy="2" :key="item.CATEGORY_CD">
            <div class="head" @click="selectedCategory1=null">
              <div class="item uk-flex-center">
                <i class="icon-absolute-left" uk-icon="chevron-left"></i>
                <h2 class="uk-margin-remove" v-html="item.CATEGORY_NAME"></h2>
              </div>
            </div>

            <label class="item item-child">
              <input class="uk-radio uk-radio-inverse icon-absolute-right" type="checkbox" @change="clear()" v-model="clearCategory" :value="item.CATEGORY_CD">
              <span class="label">指定なし</span>
            </label>

            <ul class="uk-margin-remove">
              <li v-if="categorys[child] && isITEM_006Mach(child)" v-for="child in item.CHILDREN">
                <div v-if="categorys[child].CHILDREN">
                  <div class="child">
                    <label class="item item-child">
                      <input class="uk-radio uk-radio-inverse icon-absolute-right" type="checkbox" v-model="checkedCategory" :value="categorys[child].CATEGORY_CD">
                      <span class="label" v-html="categorys[child].CATEGORY_NAME"></span>
                    </label>
                    <div class="kids">
                      <label class="item item-kids" v-if="categorys[baby]" v-for="baby in categorys[child].CHILDREN">
                        <input class="uk-radio uk-radio-inverse icon-absolute-right" type="checkbox" v-model="checkedCategory" :value="categorys[baby].CATEGORY_CD">
                        <span class="label" v-html="categorys[baby].CATEGORY_NAME"></span>
                      </label>
                    </div>
                  </div>
                </div>

                <div v-else class="child">
                  <label class="item item-child">
                    <input class="uk-radio uk-radio-inverse icon-absolute-right" type="checkbox" v-model="checkedCategory" :value="categorys[child].CATEGORY_CD">
                    <span class="label" v-html="categorys[child].CATEGORY_NAME"></span>
                  </label>
                </div>
              </li>
            </ul>
          </div>
        </transition>
      </div>
      <div class="search-foot uk-flex">
        <button class="uk-button uk-width-1-3 reset" @click="reset()">条件をクリア</button>
        <button class="uk-button uk-width-2-3 submit" @click="submit()">この条件で検索</button>
      </div>
    </div>

    <!-- data -->
    <ul class="uk-list uk-list-divider uk-width-1-2 hide">
      <li v-for="item in resultItem">
        <!--
        <a class="uk-link-heading uk-flex uk-flex-middle" :href="'https://www.karrimor.jp/item/'+item.ITEM_CD+'.html'">
          <div class="uk-width-1-5"><img :src="productImagePath+item.ITEM_IMAGE1" alt=""></div>
          <div class="uk-width-4-5">
            <span v-html="item.ITEM_NAME"></span> <span v-html="' ￥'+item.TEIKA"></span>
          </div>
        </a>
        -->
      </li>
    </ul>

  </div>
</template>

<script>
import Search from './_search.webpack.js'
export default Search;
</script>

