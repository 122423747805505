export default class {
  constructor(id,videoID,playerVars){
    this.id=id;
    this.videoID=videoID;
    this.$self = $("#"+this.id);
    this.$parent = this.$self.parent();
    this.$parent.addClass("pause");
    this.player=null;
    this.options={
      id:this.videoID,
      width: 720,
      height:480,
      byline:false,
      portrait:false,
      autoplay:true,
      controls:false,
      responsive:true,
      title:false,
      autopause:false,
      muted:true,
      background:true,
      loop: true
    }
    if(playerVars){
      this.options=Object.assign(this.options,playerVars);
    }
    if(this.options.id && !this.options.standby){
      this.init();
    }
  }
  init(){
    this.isFirstLoaded=false;
    this.player = new Vimeo.Player(this.$self, this.options);

    // 読み込み
    this.player.on('loaded', (data)=>{
      if (this.options.autoplay && !this.isFirstLoaded) {
        this.$parent.addClass("playing");
        this.$parent.removeClass("pause");
      }
      // console.log('読み込み', data,this.id);
      ROOT.ev.emit("VIMEO-READY",this.id);
      $(this).trigger("VIDEO-READY");
    });

    // 再生した時
    this.player.on('play', (data) => {
      // console.log('再生', data, this.id);
      this.isPlaying = true;
      this.$parent.addClass("playing");
      this.$parent.removeClass("pause");
      $(this).trigger("VIDEO-PLAY");
    });

    // 一時停止した時
    this.player.on('pause', (data)=>{
      // console.log('一時停止', data,this.id);
      this.isPlaying=false;

      this.$parent.removeClass("playing");
      this.$parent.addClass("pause");
      $(this).trigger("VIDEO-PAUSE");
    });

    // 最後まで再生した時
    this.player.on('ended', (data)=>{
      // console.log('再生終了', data,this.id);
      this.isPlaying=false;
      if(!this.options.loop){
        this.$parent.removeClass("playing");
        this.$parent.addClass("pause");
      }
      $(this).trigger("VIDEO-ENDED");
    });

    // シークした時
    this.player.on('seeked', (data)=>{
      // console.log('シーク', data,this.id);
    })
  }
  play(){
    if(this.player){
      this.player.play();
    } else {
      this.init();
    }
  }
  pause(){
    if(this.player)this.player.pause();
  }
  loadVideo(id){
    this.videoID=id;
    if (this.player){
      this.player.destroy();
      this.player=null;
      this.$self.empty()
    }
    this.options.id = this.videoID
    this.init();
    if (this.player && this.options.autoplay) {
      this.player.play();
      this.isPlaying = true;
      this.$parent.removeClass("pause");
    }
    // if(this.player){
    //   this.player.loadVideo(this.videoID);
    //   if(this.options.autoplay){
    //     this.player.play()
    //     this.isPlaying=true;
    //     this.$parent.removeClass("pause");
    //   }
    // }else{
    //   this.options.id=this.videoID
    //   this.init();
    //   if(this.player&&this.options.autoplay){
    //     this.player.play()

    //     this.isPlaying=true;
    //     this.$parent.removeClass("pause");
    //   }
    // }

  }
}


