export default class {
  constructor(){
    this.$body=$("body");
    this.navShow = null;
    this.$window=$(window);
    this.init()
  }
  init(){
    var self=this;
    self.isOpen = false;
    self.isHoverMenuOpen = false;
    self.menuDuration = 0.6;

    $(document).on("click",".btn-nav-toggle",function(){
      $("body").addClass("disabled-click")
      if ($("body").hasClass("navOpen")) {
        self.close();
      }
      else{
        self.open();
      }
      setTimeout(function(){
        $("body").removeClass("disabled-click")
      },self.menuDuration*1000)
      return false;
    });
    if (!ROOT.isSp && $(".header .g-menu").length) {
      var start_pos = 0;
      //スクロール方向で出し分け
      $(window).on("scroll load",function(){
        var srlTop = $(window).scrollTop();
        var headerH = $(".header").height();
        if (1 > srlTop) {
          $(".header").removeClass("js-headerTopFix")
        }
        if (srlTop) {
          var current_pos = $(window).scrollTop();
          if (current_pos > start_pos && headerH < srlTop) {
            self.hideUpHeader();
          }
          else if(headerH < srlTop){
            $(".header").addClass("js-headerTopFix")
            self.showDownHeader();
          }
          start_pos = current_pos;
        }
      });
      //hover menu
      $(".header .g-menu-item, .header .uk-grid-middle a").each(function(){
        var menu = $(this).attr("data-menu")

        $(this).hover(function(){
          if (menu) {
            self.munuOnHover(menu)
          }
          else{
            self.munuOffHover()
          }

        },function(){
          self.munuOffHover(menu)
        });

      });
    }
  }
  open(){
    this.isOpen = true;
    $(".sp-drawer-menu").removeAttr("style")
    setTimeout(function(){
      $(".sp-drawer-menu").addClass("openedMenu")
    },self.menuDuration*1000)
    $("body").removeClass("navClose").addClass("navOpen");
    ROOT.modal.open();
    $('html').addClass("navOpenHtml");
  }
  close(){
    this.isOpen = false;
    var scrTop = $( window ).scrollTop()
    console.log(scrTop)
    $(".sp-drawer-menu").css({
      top: -1 * scrTop
    })
    this.$body.removeClass("navOpen navShow navHide").addClass("navClose")
    $(".sp-drawer-menu").removeClass("openedMenu")
    setTimeout(function(){
      ROOT.modal.close();

      $('html').removeClass("navOpenHtml");
    },self.menuDuration*1000)

  }
  hideUpHeader(){
    var self=this;
    if (!self.isHoverMenuOpen) {
      $(".header").addClass("js-hideUpHeader");
    }

  }
  showDownHeader(){
    var self=this;
    $(".header").removeClass("js-hideUpHeader");
  }
  munuOnHover(str){
    var self=this;
    self.isHoverMenuOpen = true;
    $("body").removeClass("js-offHoverMenu").addClass("js-onHoverMenu");
    $(".header-hover-menu-inner.menu-"+str).addClass("js-hoverMenuOpen").removeClass("js-hoverMenuClose")
    if (self.HoverMenu != str ) {
      $(".header-hover-menu-inner.menu-"+self.HoverMenu).removeClass("js-hoverMenuOpen").addClass("js-hoverMenuClose")
    }
    self.HoverMenu = str;
  }
  munuOffHover(str){
    var self=this;
    if (str) {
      $(".header-hover-menu-inner").hover(function(){
      },function(){
        $("body").removeClass("js-onHoverMenu").addClass("js-offHoverMenu");
        $(".header-hover-menu-inner.menu-"+str).addClass("js-hoverMenuClose").removeClass("js-hoverMenuOpen")
        self.isHoverMenuOpen = false;
      });
    }
    else{
      if (self.isHoverMenuOpen) {
        $("body").removeClass("js-onHoverMenu").addClass("js-offHoverMenu");
        $(".header-hover-menu-inner").addClass("js-hoverMenuClose").removeClass("js-hoverMenuOpen")
        self.isHoverMenuOpen = false;
      }
    }
  }
}

