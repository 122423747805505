export default class {
  constructor(){
    document.addEventListener("DOMContentLoaded", (e)=> {
      if(ROOT.pageID=="member_history" && location.hash!="#debug")this.init();
    })
  }
  init(){
    $(".order-history-item").each((i,el)=>{
      var orderNumber=$('.order-number',el).text().trim()
      var href=$('.btn-order-contact',el).attr("href");
      $('.btn-order-contact',el).attr("href",href+"&order="+orderNumber);
    })
  }
}
