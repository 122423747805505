export default class {
  constructor(){
    document.addEventListener("DOMContentLoaded", (e)=> {
      if(ROOT.pageID=="input_APPLY1" && location.hash!="#debug")this.init();
    })
  }
  init(){
    if(ROOT.urlParams&&ROOT.urlParams.item){
      var itemName=decodeURIComponent(ROOT.urlParams.item);
    } else if(ROOT.urlParams&&ROOT.urlParams.order){
      var orderNumber=decodeURIComponent(ROOT.urlParams.order);
    }

    var $name1=$(`<fieldset class="uk-fieldset">
              <div class="uk-legend">お名前 <span class="uk-label">必須</span></div>
              <ul class="uk-grid-small uk-child-width-1-2" uk-grid>
                <li data-placeholder="姓">
                </li>
                <li data-placeholder="名">
                </li>
              </ul>
            </fieldset>`);

    var $name2=$(`<fieldset class="uk-fieldset">
              <div class="uk-legend">お名前（フリガナ）</div>
              <ul class="uk-grid-small uk-child-width-1-2" uk-grid>
                <li data-placeholder="セイ">
                </li>
                <li data-placeholder="メイ">
                </li>
              </ul>
            </fieldset>`);

    $('.uk-fieldset').each(function(i,el){
      var $filedset = $(el);
      var $title = $filedset.find(".uk-legend");
      var title=$title.text().trim();
      if(title.indexOf("お問い合わせする項目")!=-1){
        // label
        $(`<h3 class="uk-flex pc-text-size-20 sp-text-size-40">${$title.html()}</h3>`).prependTo($filedset);
        $title.remove()

        // body
        var listHtml ="";
        $filedset.find(".uk-form-controls .HTMLFORM_ELEMENT").each(function(i,el){
          $(el).wrap('<li></li>')
          $(el).find("input[name=FREE_ITEM1]").addClass("cNo-"+(i+1));
        })
        $filedset.find(".uk-form-controls").addClass("uk-list uk-list-large");

        if((itemName||orderNumber)&&!$("input[name=FREE_ITEM1]:checked").val())$(".cNo-2").prop("checked",true)

      } else if(title.indexOf("お名前（姓）")!=-1){
        $name1.find("li").eq(0).append($filedset.find(".uk-form-controls").html())
        $filedset.remove()
      } else if(title.indexOf("お名前（名）")!=-1){
        $name1.find("li").eq(1).append($filedset.find(".uk-form-controls").html())
        $filedset.html($name1)
      } else if(title.indexOf("フリガナ（セイ）")!=-1){
        $name2.find("li").eq(0).append($filedset.find(".uk-form-controls").html())
        $filedset.remove();
      } else if(title.indexOf("フリガナ（メイ）")!=-1){
        $name2.find("li").eq(1).append($filedset.find(".uk-form-controls").html())
        $filedset.html($name2);
      } else if(title.indexOf("お問い合わせ内容")!=-1){
        if(itemName){
          if($filedset.find("textarea").text().indexOf(itemName)==-1){
            $filedset.find("textarea").prepend("お問い合わせ商品名 : "+itemName+"\n")
          }
        }else if(orderNumber){
          if($filedset.find("textarea").text().indexOf(orderNumber)==-1){
            $filedset.find("textarea").prepend("お問い合わせ注文番号 : "+orderNumber+"\n")
          }
        }
      }



    });

  }
}
